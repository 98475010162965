<template v-if="icon">
  <router-link v-if="to" :to="to" :target="target">
    <span :class="`${(variant ? variant : 'fa')} fa-${icon}${(size ? ' fa-' + size : '')}${spin !== undefined ? ' fa-spin' : ''}${to ? ' cursor-pointer' : ''}`"></span>
  </router-link>
  <span v-else :class="`${(variant ? variant : 'fa')} fa-${icon}${(size ? ' fa-' + size : '')}${spin !== undefined ? ' fa-spin' : ''}${to ? ' cursor-pointer' : ''}`" @click="$emit('click')"></span>
</template>
<script>
export default {
  name: "FontAwesomeMaker",
  props: ["icon", "size", "spin", "variant", "to", "target"],
  mounted() {
    if (!this.icon) console.error('Missing required prop: "icon"');
  }
}
</script>
